<template>
  <div>
    <title>SIM INVENTORY ~ ADD SCRAP</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Add Scrap
        <br />
        <h4>Please enter data scrap</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Scrap</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-4">
          <!--<router-link to="/add-supplier">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link> -->

          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Scrap</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Receive Number</label>
                  <input
                    type="text"
                    v-model="isidata.nomorpenerimaan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Receive Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Receive Date</label>
                  <input
                    type="date"
                    v-model="isidata.tglpenerimaan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Receive Date"
                  />
                </div>
                
                <div class="form-group">
                  <label for="exampleInputEmail1">Dept.</label>

                  <select2
                    :data="deptlist"
                    :value="valuedept"
                    @update="updatedept($event)"
                  ></select2>


                  <!--<select
                    v-model="isidata.dept"
                    class="form-control"

                  >
                    <option
                      v-for="datalistdept in deptlist"
                      :key="datalistdept.id"
                      :value="datalistdept.kode_proses"
                    >
                      {{ datalistdept.nama_proses }}
                    </option>
                  </select> -->
                  
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Dept. Outgoing Date</label>
                  <input
                    type="date"
                    v-model="isidata.tglpengirim"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>

          &nbsp; 
          <router-link to="/scrap">
            <button class="btn btn-success">
              <i class="fa fa-angle-double-left"></i> Back
            </button>
          </router-link>
          <br><br>
        </div>

        <div class="col-md-8">
          <!--<router-link to="/add-supplier">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link> -->

          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Master Scrap Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>No.</th>
                      <th>Scrap Code</th>
                      <th>Scrap Name</th>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_pagedScrap"
                      :key="classdata.id"
                    >
                      <td>
                        <button
                          @click="saveData2(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata.kode_scrap }}</td>
                      <td>{{ classdata.nama_scrap }}</td>
                      <td>{{ classdata.unit }}</td>
                      <td>
                        <input
                          :id="'inpq-' + classdata.id"
                          type="number"
                          placeholder="ex : 3"
                          required=""
                          class="form-control"
                          v-model="classdata.qty"
                          :name="'nmq-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpn-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.notespod"
                          :name="'nmn-' + classdata.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="7" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "readsup",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        nomorpenerimaan: "",
        tglpenerimaan: "",
        tglpengirim: "",
        dept: "",
      },
      classdata: {
        kode_scrap: "",
        nama_scrap: "",
        unit: "",
        qty: "",
        notespod: ""
      },
      isExist: true,
      loading: false,
      namesearch: "",
      valuedept: "",
      list_pagedScrap: [],
      deptlist: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.listGridMasterScrap();
    this.listdept()
    this.getnow()
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      //   window.scrollTo({ top: 0, behavior: "smooth" });
      this.listGridMasterScrap();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.listGridMasterScrap();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.tglpengirim = tahun + "-" + month + "-" + tgl;
      this.isidata.tglpenerimaan = tahun + "-" + month + "-" + tgl;
    },
    updatedept(value){
      this.valuedept = value
    },
    listdept(){
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget = this.$apiurl + "daftar_proses/getdaftar_proses_list"
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.deptlist = resp.data.data;
          this.deptlist.forEach((item) => {
            item.value = item.kode_proses;
            item.label = item.nama_proses;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    listGridMasterScrap() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "scrap/getalldatascrap?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            // localStorage.setItem("dtmaterial2", JSON.stringify(resp.data.data));
            this.list_pagedScrap = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    async saveData2(classdata) {
      this.loading = true;
      var kodeuser = sessionStorage.getItem("kodeuser");
      let paramdata = new FormData();
      paramdata.append('nomor_penerimaan', this.isidata.nomorpenerimaan);
      paramdata.append('tanggal_penerimaan', this.isidata.tglpenerimaan);
      paramdata.append('kode_barang', classdata.kode_scrap);
      paramdata.append('unit', classdata.unit);
      // paramdata.append('dept', this.isidata.dept);
      paramdata.append('dept', this.valuedept);
      paramdata.append('tanggal_pengirim', this.isidata.tglpengirim);
      paramdata.append('qty', classdata.qty);
      paramdata.append('kode_user', kodeuser);
      paramdata.append('notes', classdata.notespod);
      /* const paramdata = {
        nomor_penerimaan: this.isidata.nomorpenerimaan,
        tanggal_penerimaan: this.isidata.tglpenerimaan,
        kode_barang: classdata.kode_barang,
        unit: classdata.unit,
        qty: classdata.qty,
        kode_user: kodeuser,
        notes: classdata.notespod
      }; */
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIsavedata = this.$apiurl + "waste_in/savewaste_in";
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenlogin
      };
      axios
        .post(urlAPIsavedata, paramdata, {
          headers: headers,
        })
        .then((resp) => {
          console.log(resp)
          if (resp.data.pesan == 'Code waste_in Duplicate, Cannot Saved') {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Receive Number is already exist",
              showConfirmButton: false
            });
            this.loading = false;
          } else {
            swal({
              icon: "success",
              title: "Success",
              text: "Save data successfully",
              showConfirmButton: false
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
            showConfirmButton: false
          });
          console.log(err);
          this.loading = false;
        });
    },

    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "scrap/getalldatascrap?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_pagedScrap = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
